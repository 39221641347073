import api from '../library/axios';

export default {
  getList({
    workspace_id,
    query,
  }) {
    return api({
      method: 'get',
      url: `/api/chatbot-keywords/${workspace_id}`,
      params: query,
    });
  },
  create(workspace_id, body) {
    return api({
      method: 'post',
      url: `/api/chatbot-keywords/${workspace_id}`,
      data: body,
    });
  },
  delete({
    workspace_id,
    id,
  }) {
    return api({
      method: 'delete',
      url: `/api/chatbot-keywords/${workspace_id}/${id}`,
    });
  },
  /* eslint-disable no-param-reassign */
  update(body) {
    const { id, workspace_id } = body;
    delete body.id;
    delete body.workspace_id;
    return api({
      method: 'patch',
      url: `/api/chatbot-keywords/${workspace_id}/${id}`,
      data: body,
    });
  },
  detail({
    workspace_id,
    id,
  }) {
    return api({
      method: 'get',
      url: `/api/chatbot-keywords/${workspace_id}/${id}`,
    });
  },
  validateKeyword({
    workspace_id,
    keyword,
  }) {
    return api({
      method: 'post',
      url: `/api/chatbot-keywords/${workspace_id}/validate-keyword`,
      data: {
        keyword,
      },
    });
  },
};
